<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'sms_patterns' }"> {{$t('label_sms_patterns')}}</b-breadcrumb-item>
        <b-breadcrumb-item  active>{{$t('label_category_list')}}</b-breadcrumb-item>
      </div>
    </div>



    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
                  cols="12"
                  md="5"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

          >
            <!--<label>{{ $t('label_show') }}</label>-->
            <v-select
                    v-model="tableData.perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tableData.perPageOptions"
                    :clearable="false"
                    :searchable="false"
                    class="per-page-selector d-inline-block mx-50"
            >
              <template v-slot:no-options="option">
                {{$t('label_no_items')}}
              </template>
            </v-select>

            <!--<column-chooser v-model="selectedColumns" :not-touch="['id']"  :columns="columnsDefs"></column-chooser>-->

          </b-col>


          <!-- Search -->
          <b-col
                  cols="12"
                  md="5"
                  class="ml-auto d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

              <b-form-input
                      v-model="tableData.searchQuery"
                      type="search"
                      debounce="500"
                      class="d-inline-block mr-1 "
                      :placeholder="$t('label_search')+'...'"
              />

            <b-button
                    v-if="$can('edit', 'contractregistry')"
                    @click="editedItem = false;$bvModal.show(PREFIX + '-modal')"
                    variant="primary"

            >
              <span class="text-nowrap">{{ $t('label_category_add') }}</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <!--***********************************************************TABLE*********************************************-->

<div class="px-2 table-container-wrap">
  <!--sticky-header-->
  <b-table

          :ref="PREFIX+'_TABLE'"
          class="position-relative transited-table"
          :items="tableItems"
          responsive
          no-border-collapse
          :busy="isBusy"
          :fields="columnsDefs"
          primary-key="id"
          :sort-by.sync="tableData.sortBy"
          no-local-sorting
          :sort-desc.sync="tableData.sortDesc"
          show-empty
          :tbody-transition-props="{ name: 'flip-list'}"
          :empty-text="$t('label_no_table_data')"
  >

    <template #head()="data">
      <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
    </template>

    <!--<template #cell(id)="data">-->
      <!--{{'#' + data.item.id}}-->
    <!--</template>-->
    <!--<template #cell(status)="data">-->
      <!--{{data.item.status == 1? $t('label_active') : $t('label_inactive')}}-->
    <!--</template>-->

    <template #cell(actions)="data">

      <b-dropdown
              dropleft
              boundary="window"
              variant="link"
              no-caret
              @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
              @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
              :right="$store.state.appConfig.isRTL"
      >

        <template #button-content>
          <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
          />
        </template>


        <b-dropdown-item
                @click="editedItem = data.item; $bvModal.show(PREFIX + '-modal');">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
        </b-dropdown-item>

        <b-dropdown-item @click="deleteItem(data.item.id)">
          <feather-icon icon="TrashIcon"/>
          <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>

  </b-table>
  <div class="mx-2 mb-2">
    <b-row>

      <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
      </b-col>
      <!-- Pagination -->
      <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >

        <b-pagination
                v-model="tableData.currentPage"
                :total-rows="tableTotal"
                :per-page="tableData.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</div>

    </b-card>
    <!--:module-item="false"-->
    <process-category-modal
            @item-added="refreshDataTable()"
            @item-edited="refreshDataTable()"
            :edited-item="editedItem"
    ></process-category-modal>

  </div>
</template>

<script>
    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import processCategoryModal from './modal/processCategoryModal'

    import infinityScroll from '@/views/components/infinityScroll'

    import {MODULE_PREFIX, CATEGORY_PREFIX as PREFIX} from './moduleHelper'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
        BCardBody,
            BFormCheckbox,

        BCardHeader,
        BTooltip,

            vSelect,
            infinityScroll,
            processCategoryModal
        },
        props:['tableType'],
        data() {
            return {
                PREFIX,
                MODULE_PREFIX,


                editedItem: false,


                columnsDefs: [
                    // {label: '#', key: 'id'},
                    {label: 'label_name', key: 'name', sortable: true},
                    // {label: 'label_status', key: 'status', sortable: false},
                    {label: 'label_action', key: 'actions', tdClass: 'text-right', thClass: 'text-right'}
                ],
                isBusy:false,
                tableTotal: 0,
                notFilteredData:[],
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [ 10, 25, 50, 100],
                },


            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },

        },

        created() {

            let allowed = this.columnsDefs.filter((el)=>{
                if(el.key == 'actions' && !this.$can('edit', 'sms.tab_details')){
                    return false;
                }
                return true;
            });

            this.columnsDefs = allowed;
            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {


            refreshDataTable: function () {
                this.isBusy = true;

                this.async('get',  '/'+ this.MODULE_PREFIX + '_categories', {

                    params: {

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,

                    }
                }, function (resp) {
                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },

            deleteItem(id) {
                  this.confirmDeleting((result) => {
                      this.async('delete', '/'+this.MODULE_PREFIX+'_categories/' + id , {}, function (resp) {
                          this.refreshDataTable();
                      });
                  });
            },

        },


    }
</script>

